import React from 'react';
import { Warning } from "../assets";


const AccessDenied = () => {
  return (
    <div>
      <div className="font-semibold text-lg mb-4 flex gap-10 bg-sky-100 p-5 rounded-md">
            <div className="flex justify-between flex-col gap-4">
                <div className="flex gap-2">
                    <Warning /><p>Access Denied</p>
                </div>
                <div className="flex gap-2 items-center text-sky-900 hover:text-red-900 transition-color duration-1000 ">
                    Please contact your administrator if you are required to perform on this page!
                </div>
            </div>
        </div>
    </div>
  );
}

export default AccessDenied;
