import React, {useEffect, useState}  from "react"; 

import { NumericFormat } from 'react-number-format';

const InvoiceStats = () => {
    const[stats, setStats] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
           const response = await fetch(`${process.env.REACT_APP_BASE_API}/invoice/laststats`);
           const data = await response.json();
           setStats(data);
        }

        fetchData();

    }, []);

    const importedDate = stats[0]?.importedDateStr;
    const clientTotal = stats[0]?.clientCost + stats[0]?.fuelCost + stats[0]?.hstCost;
    return(
        <div>
            <div className="rounded-lg overflow-hidden bg-sky-100 w-[400px]">
                <div className="bg-red-900 p-2 text-white gap-y-2 flex flex-col">
                        <span>Last Invoice Summary</span>
                        <div className="flex gap-10 text-sm justify-between">
                            <span>Data Imported: {importedDate}</span>
                        </div>
                </div>
                <div className="p-4 flex flex-col gap-2">                       
                        <div className="flex gap-5 justify-between">
                            <span>Client Charges Before Fuel and HST:</span>
                            <span className="font-bold"><NumericFormat value={stats[0]?.clientCost?.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></span>
                        </div> 
                        <div className="flex gap-5 justify-between">
                            <span>Fuel Charges:</span>
                            <span className="font-bold"><NumericFormat value={stats[0]?.fuelCost?.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></span>
                        </div>
                        <div className="flex gap-5 justify-between">
                            <span>Hst Charges:</span>
                            <span className="font-bold"><NumericFormat value={stats[0]?.hstCost?.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></span>
                        </div>
                        <div className="flex gap-5 justify-between">
                            <span>Total Client Charges:</span>
                            <span className="font-bold"><NumericFormat value={clientTotal?.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></span>
                        </div>
                </div>
            </div>  
        </div>
    );
}

export default InvoiceStats;