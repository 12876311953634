import React, { useEffect, useState } from 'react';
import { Navigate } from "react-router-dom";

const ProtectedRoute = ({element, isAdminOnly}) => {
    const[loggedInUser, setLoggedInUser] = useState();

    useEffect(() => {
        const storedUser = localStorage.getItem('user');
        const userObject = JSON.parse(storedUser);
        setLoggedInUser(userObject); 
    }, []);

    if(isAdminOnly && loggedInUser && loggedInUser.userLevel !== 1){
        return <Navigate to="/access" replace />
    }

    return  element;
}

export default ProtectedRoute;
