import FuelStats from "./FuelStats";
import InvoiceStats from "./InvoiceStats";
import Last4Stats from "./Last4Stats";

const Home = () => {
    
    return (
        <div className="flex gap-10">
            <Last4Stats />
           
            <div className="flex flex-col gap-10"> 
                <InvoiceStats />               
                <FuelStats />
            </div>
        </div>
        
    )
}

export default Home;