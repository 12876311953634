import { useState, useEffect } from 'react';

const Spinner = () => {
  const messages = [
    'Preparing your download...',
    'Progressing, please hold on...',
    'Taking a bit longer than usual, hang tight!',
    'We appreciate your patience, almost there...',
    'Final touches, your download is on its way!',
  ];

  const [messageIndex, setMessageIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      if (messageIndex < messages.length - 1) {
        setMessageIndex((prevIndex) => prevIndex + 1);
      } else {
        clearInterval(interval); // Clear the interval when reaching the last message
      }
    }, 12000);

    return () => clearInterval(interval);
  }, [messageIndex]); // Include messageIndex in the dependency array to avoid stale state

  return (
    <div className="fixed inset-0 flex flex-col items-center justify-center bg-black bg-opacity-50 text-white">
      <div className="border-t-transparent border-solid animate-spin rounded-full border-sky-900 border-4 h-20 w-20 mb-4"></div>
      <div className="text-lg">{messages[messageIndex]}</div>
    </div>
  );
}

export default Spinner;
