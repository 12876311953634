import { createContext, useState } from "react"; 

export const AppContext = createContext();

export const AppProvider = ({children}) => {
    const[importedDate, setImportedDate] = useState();

    return(
        <AppContext.Provider value={{ importedDate, setImportedDate}}>
            {children}
        </AppContext.Provider>
    );
}