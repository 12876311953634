import React, { useEffect, useState } from 'react';
import UserPrompt from "./UserPrompt";

function InvoiceSettings() {
    const[sequence, setSequence] = useState();
    const[showMessage, setShowMessage] = useState(false);
    const[failed, setFailed] = useState(false);
    const[message, setMessage] = useState();
    useEffect(() => {
        const fetchData = async () => {
            const response = await fetch(`${process.env.REACT_APP_BASE_API}/invoice/sequence`);
            const data = await response.json();
            setSequence(data.startSequence);
         }

         fetchData();

    }, []);

    const handleInputChange = (e) => {
        setSequence(e.target.value);
    }

    const updateSequence = async () => {
        var formData = {
            startSequence: sequence
        }

        const response = await fetch(`${process.env.REACT_APP_BASE_API}/invoice/setsequence`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json' // Set the correct content type
              },
            body: JSON.stringify(formData) 
        });

        const result = await response.json();
        
        if(result.success){        
            setFailed(false);    
            setMessage('Sequence Updated!');            
        }else{
            setFailed(true);
            setMessage('Update Failed!');  
        }

        setShowMessage(true);
        setTimeout(() => {
            setShowMessage(false);
          }, 5000);
    }

    return (
        <div>
            <div className="flex flex-col gap-3 border-2 p-4 rounded-md w-[400px] h-[150px] ">
                <div>
                    <label className="block text-gray-700 text-sm mb-2" for="username">
                        Starting FileNo Sequence:
                    </label>
                    A <input 
                        onChange={handleInputChange}
                        className="shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" value={sequence} type="number" placeholder="Sequence Number" />
                    <button onClick={updateSequence}
                        className="bg-sky-900 ml-2 hover:bg-red-800 transition-color duration-1000 text-white py-2 px-4 rounded focus:outline-none focus:shadow-outline" 
                        type="submit">Update
                    </button>
                </div>
                <div>
                    { showMessage && <UserPrompt message={message} failed={failed} /> }
                </div>
            </div>
        </div>
    );
}

export default InvoiceSettings;
