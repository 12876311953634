import React from 'react';
import Spinner from "./Spinner";

const DownloadingSpinner = ({visible}) => {
    return (
        <div
          className={`fixed inset-0 bg-gray-600 bg-opacity-50 z-50 ${
            visible ? '' : 'hidden'
          }`}
        >
          <div className="flex items-center justify-center h-full">
            <Spinner />
          </div>
        </div>
      );
}

export default DownloadingSpinner;
