import React, { useRef, useState, useContext } from 'react';
import useFileUpload from 'react-use-file-upload';
import { AppContext } from "../context/AppContext";
import { Link } from "react-router-dom";
import { Warning } from "../assets";

const ImportFile = () => {
  const appContext = useContext(AppContext);
  const {
    files,
    fileNames,
    handleDragDropEvent,
    setFiles,
    removeFile,
  } = useFileUpload();

  const[message, setMessage] = useState();
  const[progressMsg, setProgressMsg] = useState("");
  const[uploading, setUploaded] = useState(false);
  const[importCompleted, setImportCompleted] = useState(false);
  const[errorMsg, setErrorMsg] = useState('');
  const[failedFiles, setFailedFiles] = useState([]);

  const inputRef = useRef();

  const findFailedFile = (fileName) => {
    const failedFile = failedFiles.find((file) => file.fileName === fileName);
    return failedFile;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMsg('');
    setUploaded(true);
    setProgressMsg("Importing File(s)...");
    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
        formData.append("files", files[i]);
    }

    try {            
            const response = await fetch(`${process.env.REACT_APP_BASE_API}/Import/upload`, {
                method: "POST",
                body:formData
            });
            
            const fileData = await response.json();
            if(fileData.uploaded){
                appContext.setImportedDate(fileData.importedDate.substr(0, 10));        
                setProgressMsg("Processing...");
                const moveResponse = await fetch(`${process.env.REACT_APP_BASE_API}/Import/copy`, {
                    method: "POST"
                });

                const moveData = moveResponse.json();
                console.log(JSON.stringify(moveData));
    
                setImportCompleted(true);
                setMessage("File(s) imported successfully!")
            }else{
                setFailedFiles(fileData.files);
                setUploaded(false);
                setErrorMsg("Unable to import files.!")
            }
           
        } catch (error) {
            console.log(JSON.stringify(error));
            setUploaded(true);
            setErrorMsg('Failed to submit files.');        
        }
  };

  const handleRemove = (name) => {
    setErrorMsg('');
    const files = failedFiles.filter((file) => file.fileName !== name );
    setFailedFiles(files);
    removeFile(name);
  }

  return (
    <>
    <div className="flex flex-col gap-y-4 bg-sky-900 rounded-md p-4">
        <div className="text-white">
            <p className="text-2xl">Import Files</p>
            <p><p>Please use the form to your right to upload any file(s) of your choosing.</p></p>
        </div>
        <div className="grid grid-cols-2 gap-x-5">
            <div className="w-full flex flex-col items-start justify-between p-5 bg-sky-100 h-60 rounded-sm">
                <ul className="w-full">
                    {fileNames.map((name) => (
                    <li key={name}>
                        <div className="flex justify-between my-3 items-start" >
                            <div className="flex flex-col">
                                <span>{name}</span>
                                {
                                    
                                    findFailedFile(name) &&
                                    <span className="text-red-900 text-sm">{findFailedFile(name).errorMsg}</span>
                                }
                            </div>
                            <span className="hover:cursor-pointer" onClick={() => handleRemove(name)}>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-4 h-4 text-red-900">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                                </svg>
                            </span>
                        </div>
                    </li>
                    ))}
                </ul>
            </div>
            <div className="flex flex-col gap-y-5 justify-center items-center p-2 bg-white h-60 rounded-sm"
                 onDragEnter={handleDragDropEvent}
                 onDragOver={handleDragDropEvent}
                 onDrop={(e) => {
                     handleDragDropEvent(e);
                     setFiles(e, 'a');
                 }}
            >
                <p>Drag and drop csv files here</p>
                <button 
                    className="bg-sky-900 hover:bg-sky-600 transition-color duration-1000 text-white py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                    onClick={() => inputRef.current.click()}>Or select csv files to import</button>
                    <input
                    ref={inputRef}
                    type="file"
                    multiple
                    style={{ display: 'none' }}
                    onChange={(e) => {
                    setFiles(e, 'a');
                    inputRef.current.value = null;
                    }}
                />
            </div>
        </div>
        <div  className="min-h-[50px]"> 
            {files.length > 0 &&
                <div>
                   {!importCompleted &&
                    <button 
                    className="bg-red-900 hover:bg-red-600 transition-color duration-1000 text-white py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                    onClick={handleSubmit}>
                        {uploading && 
                                <div className="flex items-center">
                                    <div className="h-5 w-5 border-t-transparent border-solid animate-spin rounded-full border-white border-4"></div>
                                    <div className="ml-2">{progressMsg}</div>
                                </div>
                            }
                            {!uploading &&
                                <span>Import Files</span>
                            }
                    </button>
                   }                   
                </div>
            }
        </div>        
    </div>
    {
        errorMsg.length > 0 && files.length > 0 &&
        <div className="text-red-900 p-3 font-semibold text-xl flex gap-2">
            <Warning /> {errorMsg}
        </div>
    }
     {importCompleted &&
                        <div className="flex gap-x-5 mt-5 text-green-900">
                            <span>{message}</span>
                            <span className="font-bold uppercase hover:text-red-900">
                                <Link  to="/invoice">Generate Invoices</Link>
                            </span>
                        </div>
                    }
    </>
  );
};

export default ImportFile;