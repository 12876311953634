import React, {useEffect, useState} from 'react';
import { Link } from "react-router-dom";
import { View } from "../assets";

const SetupFuelCharge = () => {
    const [message, setMessage] = useState();
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [fuelValues, setFuelValues] = useState([
        { GroupName: 'STANDARD_SERVICE_US', Percentage: '' },
        { GroupName: 'STANDARD_SERVICE_CANADA', Percentage: '' },
        { GroupName: 'INTERNATIONAL_EXPRESS_EXPEDITED', Percentage: '' },
        { GroupName: 'DOMESTIC_EXPRESS_EXPEDITED', Percentage: '' }
    ]);

    useEffect(() => {
        const fetchData = async () => {
            const response = await fetch(`${process.env.REACT_APP_BASE_API}/fuel/last`);
            const data = await response.json();
            const start = new Date(data.endDate);
            start.setDate(start.getDate() + 1);
            const end = new Date(start);
            end.setDate(end.getDate() + 6);
            setStartDate(`${start.getFullYear()}-${String(start.getMonth() + 1).padStart(2, '0')}-${String(start.getDate()).padStart(2, '0')}`);   
            setEndDate(`${end.getFullYear()}-${String(end.getMonth() + 1).padStart(2, '0')}-${String(end.getDate()).padStart(2, '0')}`);           
        }

        fetchData();
    }, []);

    const handlePercentageChange = (index, event) => {
        const updatedFuelValues = [...fuelValues];
        updatedFuelValues[index].Percentage =  event.target.value;
        setFuelValues(updatedFuelValues);
    };

    const handleSubmit = async (event) => {
        setMessage('');
        event.preventDefault();

        const formData = {
            StartDate: startDate,
            EndDate: endDate,
            FuelValues: fuelValues,
        };

        const response = await fetch(`${process.env.REACT_APP_BASE_API}/Fuel/add`, {
            method: "POST",
            headers:{
                'Content-Type': 'application/json',
            },
            body:JSON.stringify(formData)
        });

        const data = response.text();
        setMessage("Fuel precentages added!");
    };

    const getLabel = (name) => {
        switch(name){
            case "STANDARD_SERVICE_US":
                return "Standard Service US";
            case "STANDARD_SERVICE_CANADA":
                return "Standard Service Canada";
            case "INTERNATIONAL_EXPRESS_EXPEDITED":
                return "International Express/Expediated";   
            case "DOMESTIC_EXPRESS_EXPEDITED":
                return "Domestic Express/Expediated"; 
            default:
                return "";
        }
    }

    return (
    <section>
        <div className="font-semibold text-lg mb-4 flex gap-10 bg-sky-100 p-5 rounded-md">
            <div className="flex justify-between flex-col gap-4">
                <p>Update UPS Fuel Percentage</p>
                <div className="flex gap-2 items-center text-sky-900 hover:text-red-900 transition-color duration-1000 ">
                    <View />
                    <Link className="font-normal text-sm drop-shadow rounded" to="/view">View Uploaded Fuel Percentages</Link>
                </div>
            </div>
        </div>
        <form onSubmit={handleSubmit}>
        <div className="flex flex-col w-[280px] gap-y-5">            
            {fuelValues.map((value, index) => (
                 <div className="flex flex-col gap-x-5">
                    <label className="block text-gray-700" for={value.GroupName}>
                        {getLabel(value.GroupName)}:
                    </label>
                    <input placeholder="%" value={value.Percentage} onChange={(event) => handlePercentageChange(index, event)}
                    className="shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id={value.GroupName} type="text" />
                </div>
            ))}

            <div className="flex flex-col gap-x-5">
                <label className="block text-gray-700 text-sm mb-2" for="startdate">
                Start Date:
                </label>
                <input placeholder="yyyy-mm-dd" value={startDate} onChange={(event) => setStartDate(event.target.value)} disabled={true}
                className="shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="startdate" type="date" />
            </div>
            <div className="flex flex-col gap-x-5">
                <label className="block text-gray-700 text-sm mb-2" for="enddate">
                End Date:
                </label>
                <input placeholder="yyyy-mm-dd" value={endDate} onChange={(event) => setEndDate(event.target.value)} disabled={true}
                className="shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="enddate" type="date" />
            </div>
            <div className="w-[280px]"> 
                <button 
                    className="w-full bg-sky-900 hover:bg-red-800 transition-color duration-1000 text-white py-2 px-4 rounded focus:outline-none focus:shadow-outline" 
                    type="submit">Submit
                </button>
            </div>
        </div>
        </form>
        <div>{message}</div>
    </section>
    );
}

export default SetupFuelCharge;
