import React, {useContext, useState} from "react";
import { LayoutContext } from "../context/LayoutContext";

import logo from '../assets/logo-bg.png';

const NotAuthenticatedLayout = ({children}) => {
    const[username, setUsername] = useState();
    const[password, setPassword] = useState();
    const[loginErr, setLoginErr] = useState();
    const[logging, setLogging] = useState(false);
    const { setAuthenticated } = useContext(LayoutContext);

    const handleUsernameChange = (e) => {
        setUsername(e.target.value);
    }

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    }

    const autheticateUser = async (event) => {
        event.preventDefault();
        setLogging(true);
        var formData = {
            username:username,
            password:password
        }

        const response = await fetch(`${process.env.REACT_APP_BASE_API}/User/login`, {
            method: "POST",
            headers:{
                'Content-Type': 'application/json',
            },
            body:JSON.stringify(formData)
        });

        const user = await response.json();
        if(user != null){
            localStorage.setItem("user", JSON.stringify(user));
            localStorage.setItem("userName", user.userName);
            setAuthenticated(true);
        }
        else{
            setLoginErr("Unable to login. Check your username or password.");
        }  
        
        setLogging(false); 
    }

    return (
        <div className="flex flex-col items-center justify-center h-screen bg-gradient-to-b from-sky-900 to-red-800">            
            <div className="w-full max-w-xs bg-white rounded mt-8">
                <div className="px-8 pt-6 flex justify-between items-baseline">
                    <img src={logo} alt="..." className="w-3/6 h-3/6 align-middle border-none" />
                    <p className="text-center py-5 text-lg uppercase text-gray-500 font-bold">Login</p>
                </div>                
                <form className="px-8 pt-6 pb-8 mb-4" onSubmit={autheticateUser}>
                    <div className="mb-4">
                    <label className="block text-gray-700 text-sm mb-2" for="username">
                        Username
                    </label>
                    <input 
                        onChange={handleUsernameChange}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="username" type="text" placeholder="Username" />
                    </div>
                    <div className="mb-6">
                    <label className="block text-gray-700 text-sm mb-2" for="password">
                        Password
                    </label>
                    <input 
                        onChange={handlePasswordChange}
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline" id="password" type="password" placeholder="******************" />
                    {/* <p className="text-red-500 text-xs italic">Please choose a password.</p> */}
                    </div>
                    <div className="flex items-center justify-between">
                    <button 
                        className="w-full bg-sky-900 hover:bg-red-800 transition-color duration-1000 text-white py-2 px-4 rounded focus:outline-none focus:shadow-outline" 
                        type="submit"
                    >
                        {
                            logging && 
                            <div className="flex items-center gap-x-5">
                                <div className="h-5 w-5 border-t-transparent border-solid animate-spin rounded-full border-white border-2">
                                </div>
                                <span>Authenticating...</span>
                            </div>
                            
                        }
                        {
                            !logging && 
                            <span>Sign In</span>
                        }
                    </button>
                    </div>
                </form>
                <div className="px-8 pb-8 text-sm">
                <p className="text-red-500 text-xs italic">{loginErr}</p>
                </div>
            </div>
        </div>
    );
}

export default NotAuthenticatedLayout;