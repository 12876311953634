import { useContext, useState } from "react";
import { AppContext } from "../context/AppContext";
import DropdownList from "./DropdownList";
import InvoiceClientList from "./InvoiceClientList";

const GenerateInvoices = () => {
    const{importedDate, setImportedDate} = useContext(AppContext);
    const[inputDate, setInputDate] = useState();
    
    const handleInputChange = (e) => {
        setInputDate(e.target.value);
    }

    const handleDateChange = (value) => {
        setImportedDate(inputDate);
    }

    return(
        <div className="bg-sky-100 p-5 rounded-md flex flex-col gap-5">
            <DropdownList />
            <InvoiceClientList />
        </div>
    );
}

export default GenerateInvoices;