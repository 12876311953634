import React, { useEffect, useState } from "react";
import AuthenticatedLayout from "../components/AuthenticatedLayout";
import NotAuthenticatedLayout from "../components/NotAuthenticatedLayout";

export const LayoutContext = React.createContext();

const LayoutContextProvider = ({children}) => {
    const[isAuthenticated, setAuthenticated] = useState(localStorage.getItem('user') !== null);
    useEffect(() => {
        const storedAuth = localStorage.getItem('user') !== null;
        if(storedAuth){
            setAuthenticated(true);
        }
    }, []);

    const currentLayout = isAuthenticated ? 
                        <AuthenticatedLayout>{children}</AuthenticatedLayout>:
                        <NotAuthenticatedLayout>{children}</NotAuthenticatedLayout>

    return (
        <LayoutContext.Provider value={{currentLayout, setAuthenticated}}>
            {currentLayout}
        </LayoutContext.Provider>
    );
}

export default LayoutContextProvider;