import React from "react";
import { BrowserRouter as Router, Routes, Route} from "react-router-dom";
import LayoutContextProvider, { LayoutContext } from "./context/LayoutContext";
import { AppProvider, AppContext } from "./context/AppContext";
import Home from './components/Home';
import GenerateInvoices from './components/GenerateInvoices';
import ImportFile from "./components/ImportFile";
import SearchInvoice from "./components/SearchInvoice";
import SetupFuelCharge from "./components/SetupFuelCharge";
import ViewFuelPercentages from "./components/ViewFuelPercentages";
import Clients from "./components/Clients";
import  Users  from "./components/Users";
import InvoiceSettings from "./components/InvoiceSettings";
import AccessDenied from "./components/AccessDenied";
import ProtectedRoute from "./components/ProtectedRoute";

function App() {
  return (
    <AppProvider> 
      <Router>
        <LayoutContextProvider>       
              <Routes>
                <Route exact path="/" element={<Home />} />
                <Route path="/import" element={<ImportFile />} />
                <Route path="/invoice" element={<GenerateInvoices />} />
                <Route path="/search" element={<SearchInvoice />} />
                <Route path="/fuel" element={<ProtectedRoute isAdminOnly={true} element={<SetupFuelCharge />} />} />
                <Route path="/view" element={<ViewFuelPercentages />} />
                <Route path="/clients" element={<Clients />} />
                <Route path="/users" element={<Users />} /> 
                <Route path="/settings" element={<InvoiceSettings />} />
                <Route path="/access" element={<AccessDenied />} />
              </Routes>            
        </LayoutContextProvider>
      </Router>
    </AppProvider>
  );
}

export default App;
