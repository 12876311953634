import { Option, Select } from "@material-tailwind/react";
import {BsFiletypePdf, BsFileEarmarkExcel} from 'react-icons/bs';
import { useState } from "react";
import { Warning }  from "../assets";
import DownloadingSpinner from "./DownloadingSpinner";

const SearchInvoice = () => {
  const[searchBy, setSearchBy] = useState('account');
  const[type, setType] = useState('text');
  const[searchValue, setSearchValue] = useState('');
  const[searchMessage, setSearchMessage] = useState('');
  const[searchedResults, setSearchedResults] = useState([]);
  const[searching, setSearching] = useState(false);
  const[downloading, setDownloading] = useState(false);

  const handleChange = (value) => {
    if(value === 'invoicedate'){
      setType('date');
    }else{
      setType('text');
    }

    setSearchBy(value);
  }

  const handleTextChange = (e) => {
    setSearchValue(e.target.value);
  }

  const submitSearch = async () => {
    setSearchedResults([]);
    setSearchMessage('');
    setSearching(true);
    const formData = {
      searchBy: searchBy,
      searchValue: searchValue
    };

    const response = await fetch(`${process.env.REACT_APP_BASE_API}/invoice/search`, {
      method: "POST",
      headers:{
          'Content-Type': 'application/json',
      },
      body:JSON.stringify(formData)
    });

    const data = await response.json();
    setSearching(false);
    if(data.length === 0){
      setSearchMessage("No results found!  Please enter valid information.")
    }
    else{
      setSearchedResults(data);
    }
  }

  const downloadPdf = async (id, billToCompany, invoiceDate) => {
    setDownloading(true);
    var formData = {
        id:id
    }

    const response = await fetch(`${process.env.REACT_APP_BASE_API}/Invoice/createpdf`, {
        method: "POST",
        headers:{
            'Content-Type': 'application/json',
        },
        body:JSON.stringify(formData)
    });

    setDownloading(false);
    const blob = await response.blob();
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    const date = new Date(invoiceDate);
    link.setAttribute('download', `Invoice-${billToCompany}-${date.toISOString().slice(0, 10).replace(/-/g, "-")}.pdf`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

  return (
    <div className="w-full">
      <DownloadingSpinner visible={downloading}/>
       <div className="flex flex-col gap-3 bg-sky-100 p-5 rounded-md">
          <div className="w-1/5">
            <Select label="Search By" value={searchBy} onChange={handleChange}>
                <Option value="account">Account Number</Option>
                <Option value="customer">Customer</Option>            
                <Option value="invoicedate">Invoice Date</Option>
            </Select>
          </div>
          <div className="w-1/5">
            <input placeholder="Enter Value" onChange={handleTextChange}
                        className="shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline w-full" type={type} />
          </div>
          <div>
            <button onClick={submitSearch}
                  className="bg-sky-900 hover:bg-red-800 transition-color duration-1000 text-white py-2 px-4 rounded focus:outline-none focus:shadow-outline" 
                  type="button">
                    {
                      searching && 
                      <div className="flex items-center justify-end gap-x-3">
                        <div className="h-3 w-3 border-t-transparent border-solid animate-spin rounded-full border-white border-2">
                        </div>
                        <span>Searching...</span>
                    </div>
                    }
                    {
                      !searching &&
                      <span>Search</span>
                    }

                  </button>
          </div>
       </div>
       <div className="mt-10">
        {
          searchMessage.length > 0 && 
          <div className="flex gap-3 text-red-900">
            <Warning />
            <h2 className="font-semibold text-lg">{searchMessage}</h2>
          </div>
        }
        {
          searchedResults.length > 0 &&
          <table className="min-w-full bg-white">
              <thead className="bg-sky-800 text-white">
                  <tr>                             
                      <th className="w-4/12 text-left py-3 px-4 uppercase font-semibold text-sm">Customer</th>
                      <th className="w-2/12 text-left">Invoice Date</th>
                      <th className="w-3/12 text-left py-3 uppercase font-semibold text-sm">Imported Date</th>
                      <th className="w-2/12 text-right py-3 px-4 uppercase font-semibold text-sm">Invoice Status</th>
                  </tr>
              </thead>
              <tbody className="text-gray-700">
                  {
                      searchedResults.map( item => (
                          <tr key={item.id}>                                     
                              <td className="w-4/12 text-left py-3 px-4">{item.billToCompany.toUpperCase()}</td>
                              <td className="w-2/12">{item.invoiceDate.substr(0, 10)}</td>
                              <td className="w-2/12">{item.importedDate.substr(0, 10)}</td>
                              <td className="w-2/12 text-right py-3 px-4">
                                  {
                                    item.invoiceCreated && 
                                    <span className="flex justify-end gap-x-3 text-right">
                                        <button className="text-red-900 text-lg" onClick={() => downloadPdf(item.id, item.billToCompany, item.invoiceDate)}>
                                            <BsFiletypePdf />
                                        </button>
                                        <button className="text-green-900 text-lg" onClick={() => downloadPdf(item.id, item.billToCompany, item.invoiceDate)}>
                                            <BsFileEarmarkExcel />
                                        </button>
                                    </span>
                                  }
                                  {
                                    !item.invoiceCreated &&
                                    <span>NOT CREATED</span>
                                   }

                              </td>
                          </tr>
                      ))
                  }
              </tbody>
          </table>
        }        
       </div>
    </div>
  );
}

export default SearchInvoice;
