import React, {useContext, useEffect, useState} from "react";
import { Link } from "react-router-dom";
import logo from '../assets/logo-bg.png';
import { LayoutContext } from "../context/LayoutContext";
import { Fuel, Gear, ImportFile, Plus, Search, UserGroup, Users } from "../assets";

const AuthenticatedLayout = ({children}) => {
    const {setAuthenticated} = useContext(LayoutContext);
    const [loggedInUser, setLoggedInUser] = useState();
    let logoutTimer;

    useEffect(() => {
        setListeners();
        startTimer();
        const storedUser = localStorage.getItem('user');
        const userObject = JSON.parse(storedUser);
        setLoggedInUser(userObject);   
        
        return () => {
            clearListeners();
            clearTimeout(logoutTimer);
        };
    }, []);

    const handleAuth = () => {
        localStorage.removeItem("user");
        localStorage.removeItem("userName");
        setAuthenticated(false);
    }

    const setListeners = () => {
        window.addEventListener('mousemove', resetTimer);
        window.addEventListener('keydown', resetTimer);
    };

    const clearListeners = () => {
        window.removeEventListener('mousemove', resetTimer);
        window.removeEventListener('keydown', resetTimer);
    };

    const startTimer = () => {
        // Set timeout to 15 minutes (900000 milliseconds)
        logoutTimer = setTimeout(() => {
            handleAuth();
            clearListeners();
        }, 900000);
    };

    const resetTimer = () => {
        clearTimeout(logoutTimer);
        startTimer();
    };

    return (
        <div className="flex font-sunflower">
            <div className="flex flex-col items-center text-center w-80 p-5 bg-sky-900 min-h-screen text-white">
                <a className="flex justify-center" href="/">
                    <div className="flex items-center justify-center bg-white bg-white h-36 w-36 rounded-full">
                        <img src={logo} alt="..." className="w-5/6 h-auto align-middle border-none" />
                    </div>
                </a>
                <div className="mt-16">
                    <Link className="flex hover:text-sky-300 transition-color duration-1000 gap-4 py-3" to="/import">
                        <ImportFile />
                        <span>Import CSV</span>
                    </Link>
                    <Link className="flex  gap-4 py-3 hover:text-sky-300 transition-color duration-1000" to="/invoice">
                        <Plus />
                        <span>Generate Invoices</span>
                    </Link>
                    <Link className="flex hover:text-sky-300 transition-color duration-1000 gap-4 py-3" to="/search">
                        <Search />
                        <span>Search Invoice</span>
                    </Link>  
                    {
                        loggedInUser && loggedInUser.userLevel == 1 &&
                        <div>                                             
                            <Link className="flex hover:text-sky-300 transition-color duration-1000 gap-4 py-3" to="/fuel">
                                <Fuel />
                                <span>Fuel Surcharges</span>
                            </Link>
                            <Link className="flex hover:text-sky-300 transition-color duration-1000 gap-4 py-3" to="/clients">
                                <UserGroup />
                                <span>Manage Clients</span>
                            </Link>
                            <Link className="flex hover:text-sky-300 transition-color duration-1000 gap-4 py-3" to="/users">
                                <Users />
                                <span>Manage Users</span>
                            </Link>
                            <Link className="flex hover:text-sky-300 transition-color duration-1000 gap-4 py-3" to="/settings">
                                <Gear />
                                <span>Settings</span>
                            </Link>
                        </div>
                    }
                   
                </div>
            </div>
            <div className="w-full">
                <div className="w-full p-3 bg-red-800 text-white flex gap-8 pr-4 justify-start">
                    <a href="/" className="flex"> 
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15M12 9l-3 3m0 0l3 3m-3-3h12.75" />
                        </svg>
                        <span><button onClick={handleAuth}>LOGOUT</button></span>
                    </a>
                    <span className="flex gap-1">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z" />
                        </svg>
                        <label className="uppercase">{localStorage.getItem('userName')}</label>
                    </span>                    
                </div>
                <div className="p-5">
                     {children}
                </div>
            </div>
        </div>
    );
}

export default AuthenticatedLayout;