import { Option, Select } from "@material-tailwind/react";
import React, { useContext, useEffect, useState } from  'react';
import { AppContext } from "../context/AppContext";

const DropdownList = () => {
    const{importedDate, setImportedDate} = useContext(AppContext);
    const[importedDates, setImportedDates] = useState([]);

    useEffect(() => {
        const fetchDates = async () => {
            const response = await fetch(`${process.env.REACT_APP_BASE_API}/import/importeddates`);
            const data = await response.json();
            const trimmedData = data.map(date => date.substr(0, 10));
            setImportedDates(trimmedData);            
        }

        fetchDates();
    }, []);

    const handleOnChange = (value) => {        
        setImportedDate(value);
    }

    return (
        <div className="w-full min-h-[60px] flex flex-col gap-2">
            <h1>Select imported date to generate invoices</h1>
            <div className="w-80">
                <Select label="Imported Dates" color="blue-gray" onChange={handleOnChange}>
                    {
                        importedDates.map((date, index) => (
                            <Option key={index} value={String(date)}>{String(date)}</Option>
                        ))
                    }
                </Select>
            </div>
        </div>
    );
}

export default DropdownList;

