import React, { useEffect, useState } from 'react';
import { Pencil } from "../assets";
import { DataGrid } from '@mui/x-data-grid';
import { Dialog, DialogContent, DialogTitle} from "@mui/material";
import UserPrompt from "./UserPrompt";

const Users = () => {
  const[users, setUsers] = useState([]);
  const[openUser, setOpenUser] = useState(false);
  const[user, setUser] = useState({});
  const[message, setMessage] = useState('');
  const[failed, setFailed] = useState(false);
  const[reload, setReload] = useState(false);
  const[currentUser, setCurrentUser] = useState({});

  const apiUrl = `${process.env.REACT_APP_BASE_API}/user/list`;
 
  const fetchData = async () => {
    const response = await fetch(apiUrl);
    const data = await response.json();
    setUsers(data);
  }

  useEffect(() => {
    const storedUser = localStorage.getItem('user');
    const userObject = JSON.parse(storedUser);
    setCurrentUser(userObject);   

    fetchData();
  }, [apiUrl]);

  const handleUserEdit = (user) => {
    setOpenUser(true);
    setUser(user);
  }

  const handleUserSubmit = async (e) => {
    e.preventDefault();
    setMessage('');
    setFailed(false);
    console.log(user);
    const response = await fetch(`${process.env.REACT_APP_BASE_API}/user/upsert`, {
      method: "POST",
      headers:{
          'Content-Type': 'application/json',
      },
      body:JSON.stringify(user)
    });

    const data = await response.json();    
    if(data.success){
      setMessage("User Info saved successfully.");
      fetchData();
    }
    else{
      setFailed(true);
      setMessage("Unable to save.  Check your values!");
    }
  }

  const handleInputChange = (e) => {
    const { name, type, checked, value } = e.target;
    setUser((prev) => {
      if (type === 'checkbox') {
        return { ...prev, [name]: checked ? 1 : 2};
      } else {
          return { ...prev, [name]: value };
      }
    });
  }

  const handleClose = (e) =>{
    e.preventDefault();
    setOpenUser(false);
    setMessage("");
    setFailed(false);
  }

  const addNewUser = () => {
    setUser({});
    setOpenUser(true);
  }

  const deleteUser = async (e) => {
    e.preventDefault();
    const response = await fetch(`${process.env.REACT_APP_BASE_API}/user/delete/${user.id}`, {
        method: 'DELETE',
        headers:{
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
    });

    const data = await response.json();
    fetchData();
    setOpenUser(false);
  }

  const baseColumns = [
      {field: 'userName', headerName:'User Name', width:300},
      {field:'email', headerName:'Email', width:500}
  ];

  let columns = [...baseColumns];

  if (currentUser.userLevel == 1) {
      const editColumn = {
          field:'edit',
          headerName:'Action',
          width:300,
          sortable: false, 
          filterable: false,
          disableColumnMenu:true,
          renderCell:(params) => (
              <div className="cursor-pointer flex text-sky-900 gap-x-4">
                  <div><Pencil onClick={() => handleUserEdit(params.row)}/></div>
              </div>
          )
      };

      columns.push(editColumn);
  }


  return (
    <div>
      { currentUser.userLevel == 1 && 
        <div>
        <button className="bg-sky-900 text-white py-2 px-4 rounded-md text-sm font-light" type="submit" onClick={() => addNewUser()}>Add New Client</button>
      </div>
      }
      <Dialog fullWidth="lg" open={openUser}>
        <DialogTitle>
          <div className="flex items-center justify-between">
            <span>User</span>
            <div>
                  {
                    message.length > 0 &&
                    <UserPrompt message={message} failed={failed} />
                  }
                </div>
          </div>
        </DialogTitle>
        <DialogContent>
        <form onSubmit={handleUserSubmit}>
            <div className="flex flex-col gap-3">
                <div className="flex flex-col">
                  <label>Username:</label>
                  <input
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                    type="text" name="userName" value={user?.userName} onChange={handleInputChange} />
                </div>   
                <div className="flex flex-col">
                  <label>Email:</label>
                  <input
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                    type="text" name="email" value={user?.email} onChange={handleInputChange}  />
                </div>      
                <div className="flex flex-col">
                  <label>Password:</label>
                  <input
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                    type="password" name="password" value={user?.password} onChange={handleInputChange}  />
                </div>    
                <div className="flex gap-4 items-center">                  
                    <input 
                      type="checkbox" name="userLevel" 
                      className="w-5 h-5 border-gray-300 rounded-lg accent-sky-900"
                      checked={user?.userLevel === 1} onChange={handleInputChange} />
                      <label>Is Admin</label>
                  </div>  
                <div className="flex justify-between">                  
                  <div className="flex justify-between w-full">
                    {
                      user.id > 0 &&
                      <div>
                        <button className="bg-red-900 text-white py-2 px-4 rounded-md text-sm font-light" onClick={deleteUser}>Delete User</button>
                      </div>
                    }
                    
                    <div className="flex justify-end gap-5">
                      <button className="bg-red-900 text-white py-2 px-4 rounded-md text-sm font-light" onClick={handleClose}>Close</button>
                      <button className="bg-sky-900 text-white py-2 px-4 rounded-md text-sm font-light" type="submit" >Save</button>
                    </div> 
                  </div>
                </div>
            </div> 
          </form>   
        </DialogContent>
      </Dialog>
      <DataGrid 
        rows={users} 
        columns={columns} 
        disableColumnSelector/>
    </div>
  );
}

export default Users;
