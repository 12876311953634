import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { Trash } from "../assets";
import { Dialog, DialogContent, DialogTitle} from "@mui/material";

const ViewFuelPercentages = () => {
    const[currentUser, setCurrentUser] = useState({});
    const[percentages, setPercentages] = useState([]);
    const[openConfirm, setOpenConfirm] = useState(false);
    const[startDate, setStartDate] = useState();
    const[endDate, setEndDate] = useState();
    
    const fetchFuelCharges = async () => {
        const response = await fetch(`${process.env.REACT_APP_BASE_API}/fuel/list`);
        const result = await response.json();
        setPercentages(result);
    }

    useEffect(() => {
        const storedUser = localStorage.getItem('user');
        const userObject = JSON.parse(storedUser);
        setCurrentUser(userObject);
        fetchFuelCharges();
    }, []);

    const handleDelete = async () => {
        const response = await fetch(`${process.env.REACT_APP_BASE_API}/fuel/delete?startDate=${startDate}&endDate=${endDate}`, {
            method: 'DELETE',
            headers:{
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        });

        const data = await response.json();
        console.log(data);
        if(data.success){
           
            fetchFuelCharges();
        }
    }

    return (
        <div>
            <div>
                <Link className="font-normal text-sm text-white bg-sky-700 p-2 drop-shadow rounded hover:bg-sky-900 hover:text-white transition-color duration-1000" to="/fuel">Back To Setup Fuel Percentages</Link>
            </div>
            <div className="mt-10">
                <div className="flex flex-col">
                    <table className="min-w-full">
                        <thead className="bg-sky-900 border-b">
                            <tr>
                                <th scope="col" className="text-sm font-medium text-white px-6 py-4 text-left">
                                    Standard Service US
                                </th>
                                <th scope="col" className="text-sm font-medium text-white px-6 py-4 text-left">
                                    Standard Service Canada
                                </th>
                                <th scope="col" className="text-sm font-medium text-white px-6 py-4 text-left">
                                    International Express/Expediated
                                </th>
                                <th scope="col" className="text-sm font-medium text-white px-6 py-4 text-left">
                                    Domestic Express/Expediated
                                </th>
                                <th scope="col" className="text-sm font-medium text-white px-6 py-4 text-left">
                                    Start Date
                                </th>
                                <th scope="col" className="text-sm font-medium text-white px-6 py-4 text-left">
                                    End Date
                                </th>
                                <th scope="col" className="text-sm font-medium text-white px-6 py-4 text-left">                                    
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                percentages.map((item, index) => (
                                    <tr key={index} className="bg-white border-b transition duration-300 ease-in-out hover:bg-gray-100">
                                        <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                            {item.standarD_SERVICE_US.toFixed(2)} %
                                        </td>
                                        <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                            {item.standarD_SERVICE_CANADA.toFixed(2)} %
                                        </td>
                                        <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                            {item.internationaL_EXPRESS.toFixed(2)} %
                                        </td>
                                        <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                            {item.domestiC_EXPRESS.toFixed(2)} %
                                        </td>
                                        <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                            {item.startDate.substr(0, 10)}
                                        </td>
                                        <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                            {item.endDate.substr(0, 10)}
                                        </td>
                                        <td>
                                            {currentUser.userLevel == 1 &&
                                                <Trash onClick={() => {setStartDate(item.startDate.substr(0, 10)); setEndDate(item.endDate.substr(0, 10)); setOpenConfirm(true)}} />
                                            }                                            
                                        </td>
                                    </tr>
                                ))
                            }
                           
                        </tbody>
                    </table>
                </div>
            </div>
            <Dialog fullWidth={true} maxWidth="sm" open={openConfirm}>
            <DialogTitle>Confirm!</DialogTitle>
            <DialogContent>
                <div className="flex flex-col gap-4">
                    <p>Are you sure you want to delete this record?</p>
                    <div className="flex gap-4 items-center justify-end">
                        <button onClick={() => setOpenConfirm(false)}
                            className="bg-sky-900 hover:bg-sky-700 transition-color duration-1000 text-white py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="button">
                                No
                            </button>
                        <button onClick={() => {handleDelete(); setOpenConfirm(false)}}
                            className="bg-red-900 hover:bg-red-700 transition-color duration-1000 text-white py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="button">
                                Yes
                            </button>
                    </div>
                </div>                
            </DialogContent>
        </Dialog>
        </div>
    );
}

export default ViewFuelPercentages;
