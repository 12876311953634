import React, {useEffect, useState}  from "react"; 

const FuelStats = () => {
    const [fuelData, setFuelData] = useState();

    useEffect(() => {
         const fetchData = async () => {
            const response = await fetch(`${process.env.REACT_APP_BASE_API}/fuel/last`);
            const data = await response.json();
            setFuelData(data);
         }

         fetchData();

    }, []);

  return (
    <div>
       <div className="rounded-lg overflow-hidden bg-sky-100 w-[400px] shadow-xl shadow-gray-300">
            <div className="bg-red-900 p-2 text-white gap-y-2 flex flex-col">
                <span>Last Updted Fuel Surcharge</span>                   
                <div className="flex gap-10 text-sm justify-between">
                    <span>Start Date: {fuelData?.startDate?.substr(0, 10)}</span>
                    <span>End Date: {fuelData?.endDate?.substr(0, 10)}</span>
                </div>
            </div>
            <div className="p-4 flex flex-col gap-2">
                    <div className="flex gap-4">
                        <span className="w-[250px] font-bold">Standard US:</span>
                        <span>{fuelData?.standarD_SERVICE_US?.toFixed(2)} %</span>
                    </div>
                    <div className="flex gap-4">
                        <span className="w-[250px] font-bold">Standard CANADA:</span>
                        <span>{fuelData?.standarD_SERVICE_CANADA?.toFixed(2)} %</span>
                    </div> 
                    <div className="flex gap-4">
                        <span className="w-[250px] font-bold">International Express/Expediated:</span>
                        <span>{fuelData?.internationaL_EXPRESS?.toFixed(2)} %</span>
                    </div>
                    <div className="flex gap-4">
                        <span className="w-[250px] font-bold">Domestic Express/Expediated:</span>
                        <span>{fuelData?.domestiC_EXPRESS?.toFixed(2)} %</span>
                    </div>                   
            </div>
            <div>
            <div className="text-right">
                            <a href="/fuel"
                                className="bg-sky-900 hover:bg-red-800 transition-color duration-1000 text-white py-2 px-4 rounded focus:outline-none focus:shadow-outline" 
                            >Setup Fuel Surcharge</a>
                        </div>
            </div>
          </div>  
    </div>
  );
}

export default FuelStats;
