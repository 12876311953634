import { useEffect, useState } from "react";
import { Close } from "../assets";

const Accessorial = ({id, onClose}) => {

    const columns = 8;
    const rowsPerColumn = Math.ceil(57 / columns);

    const [accessorials, setAccessorials] = useState([]);
    const [showMessage, setShowMessage] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            const response = await fetch(`${process.env.REACT_APP_BASE_API}/discount/accessorial/${id}`);
            const data = await response.json();
            setAccessorials(data);
            console.log(data);
        }
        
        fetchData();
    }, [id]);

    const getDiscountValue = (name) => {
        const accessorialObj = accessorials.find((accessorial) => accessorial.name == name);
        return accessorialObj ? accessorialObj.markup : 0;
    }

    const handleNotFlatRateChange = (e) => {
        const {name, value} = e.target;
        const updatedAccessorials = accessorials.map((accessorial) => {
            if (accessorial.name == name) {
                return { ...accessorial, markup: value };              
            }

            return accessorial;
          });
      
        setAccessorials(updatedAccessorials);
    }

    const handleApplyChanges = async () => {
        const response = await fetch(`${process.env.REACT_APP_BASE_API}/discount/accessorial/upsert`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json' // Set the correct content type
              },
            body: JSON.stringify(accessorials) 
        });

        const result = await response.json();
        setShowMessage(true);
    }

    return (
        <div>          
            <div className="flex justify-between items-end mb-4 border-b-4 divide-slate-200">
                <span className="text-lg font-bold text-sky-900">Set Customer Accessorial Markdown</span>
                <div className="flex items-center justify-between gap-6 mb-2">
                    {
                        showMessage && 
                        <div className="text-green-900 font-lg font-bold">
                            <label>Discounts saved successfully!</label>
                        </div>
                    }
                    <button onClick={() => handleApplyChanges()} className="bg-sky-900 text-white py-2 px-4 rounded-md text-sm font-light">Save Changes</button>
                    <button onClick={() => onClose()} className="bg-red-900 text-white py-2 px-4 rounded-md text-sm font-light">Close</button>
                </div>               
            </div>
             <div className="grid grid-cols-8 gap-8">
                {Array.from({ length: columns }).map((_, columnIndex) => (
                    <div key={columnIndex} className="flex flex-col gap-2">
                    {accessorials.slice(
                        columnIndex * rowsPerColumn,
                        (columnIndex + 1) * rowsPerColumn
                    ).map((accesorial) => (
                        <div key={accesorial.id} className="flex gap-2 items-center">
                            <label>{accesorial.name}</label>
                            <input name={accesorial.name} value={getDiscountValue(accesorial.name)} onChange={(e) => handleNotFlatRateChange(e)}
                            className="w-12 shadow appearance-none border rounded py-1 px-1 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            type="text" />
                        </div>
                    ))}
                    </div>
                ))}
            </div>
        </div>
    );
}

export default Accessorial;
