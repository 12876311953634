import { Check, Warning } from "../assets";


const UserPrompt = ({message, failed}) => {
  return (
    <div>
        {
            !failed && 
            <div className="flex gap-3 text-green-900">
                <Check />
                <span>{message}</span>
            </div>
        }

        {
            failed && 
            <div className="flex gap-3 text-red-900">
            <Warning />
            <span>{message}</span>
        </div>
        }
    </div>
  );
}

export default UserPrompt;
