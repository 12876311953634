import { useEffect, useState } from "react";
import { Close } from "../assets";

const Discount = ({id, onClose}) => {

    const zones = [200,201,202,203,204,205,206,207,208,209,210,211,212,241,242,243,244,245,246,247,291,292,293,294,295,296,297,300,301,302,303,304,305,306,307,308,309,310,311,312,361,362,365,366,367,368,369,370,381,382,383,384,385,386,387,400,401,402,403,404,405,406,407,408,409,410,411,412,461,462,481,482,483,484,485,486,487,491,492,493,494,495,496,497,500,501,502,503,504,505,506,507,508,509,510,511,512,561,562,581,582,583,584,585,586,587,601,602,603,604,605,606,607,608,609,610,651,652,655,656,657,658,659,660,661,700,701,702,703,704,705,706,707,708,709,710,711,712,751,752,755,756,757,758,759,760,761,762,801,802,803,804,805,806,807,808,809,810,851,852,855,856,857,858,859,860,861,862,901,902,903,904,905,906,907,908,909,910];
    const columns = 12;
    const rowsPerColumn = Math.ceil(zones.length / columns);

    const [flatRate, setFlatRate] = useState(false);
    const [discounts, setDiscounts] = useState([]);
    const [showMessage, setShowMessage] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            const response = await fetch(`${process.env.REACT_APP_BASE_API}/discount/${id}`);
            const data = await response.json();
            setDiscounts(data);
        }
        
        fetchData();
    }, [id]);

    const getDiscountValue = (zone) => {
        const discountObj = discounts.find((discount) => discount.zoneNo == zone);
        return discountObj ? discountObj.packageDiscount : 0;
    }

    const handleNotFlatRateChange = (e) => {
        const {name, value} = e.target;
        const updatedDiscounts = discounts.map((discount) => {
            if (discount.zoneNo == name) {
                return { ...discount, packageDiscount: value };              
            }

            return discount;
          });
      
        setDiscounts(updatedDiscounts);
    }

    const handleApplyChanges = async () => {
        const response = await fetch(`${process.env.REACT_APP_BASE_API}/discount/upsert`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json' // Set the correct content type
              },
            body: JSON.stringify(discounts) 
        });

        const result = await response.json();
        setShowMessage(true);
    }

    return (
        <div>          
            <div className="flex justify-between items-end mb-4 border-b-4 divide-slate-200">
                <span className="text-lg font-bold text-sky-900">Client Discounts</span>
                <div className="flex items-center justify-between gap-6 mb-2">
                    {
                        showMessage && 
                        <div className="text-green-900 font-lg font-bold">
                            <label>Discounts saved successfully!</label>
                        </div>
                    }
                    <button onClick={() => handleApplyChanges()} className="bg-sky-900 text-white py-2 px-4 rounded-md text-sm font-light">Save Changes</button>
                    <button onClick={() => onClose()} className="bg-red-900 text-white py-2 px-4 rounded-md text-sm font-light">Close</button>
                </div>               
            </div>
             <div className="grid grid-cols-12 gap-8">
                {Array.from({ length: columns }).map((_, columnIndex) => (
                    <div key={columnIndex} className="flex flex-col gap-2">
                    {zones.slice(
                        columnIndex * rowsPerColumn,
                        (columnIndex + 1) * rowsPerColumn
                    ).map((zone) => (
                        <div key={zone} className="flex gap-2 items-center">
                            <label>{zone}</label>
                            <input disabled={flatRate} name={zone} value={getDiscountValue(zone)} onChange={(e) => handleNotFlatRateChange(e)}
                            className="w-12 shadow appearance-none border rounded py-1 px-1 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            type="text" />
                        </div>
                    ))}
                    </div>
                ))}
            </div>
        </div>
    );
}

export default Discount;
